import {
    faArrowUpRightFromSquare,
    faChartSimple,
    faCheckCircle,
    faCircleExclamation,
    faCirclePlay,
    faCircleXmark,
    faFlask,
    faInfoCircle,
    faPenToSquare,
    faPlay,
    faSignOut,
    faStop,
    faTemperatureHalf,
    faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type IconProps = Omit<FontAwesomeIconProps, "icon">;

export const ArrowUpRightFromSquareIcon = (props: IconProps) => (
    <FontAwesomeIcon {...props} icon={faArrowUpRightFromSquare} />
);
export const PenToSquareIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faPenToSquare} />;
export const SignOutIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faSignOut} />;
export const TemperatureHalfIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faTemperatureHalf} />;
export const FlaskIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faFlask} />;
export const InfoCircleIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faInfoCircle} />;
export const CirclePlayIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCirclePlay} />;
export const PlayIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faPlay} />;
export const StopIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faStop} />;
export const TrashCanIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faTrashCan} />;
export const CheckCircleIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCheckCircle} />;
export const CircleExclamationIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleExclamation} />;
export const CircleXmarkIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faCircleXmark} />;
export const ChartSimpleIcon = (props: IconProps) => <FontAwesomeIcon {...props} icon={faChartSimple} />;
