import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalculationStatus } from "@/types/calculation.types";

export type RequestErrorState = {
    status: number | undefined;
    errorMessages?: string[];
};

export type CalculationStoreState = {
    id: string | null;
    status: CalculationStatus | null;
    requestError: RequestErrorState | null;
};

const INITIAL_STATE: CalculationStoreState = {
    id: null,
    status: null,
    requestError: null,
};

const calculationSlice = createSlice({
    name: "calculation",
    initialState: INITIAL_STATE,
    reducers: {
        startCalculation: (state, action: PayloadAction<CalculationStatus | null>) => {
            state.status = action.payload;
            state.requestError = null;
        },
        setCalculationId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setCalculationRequestError: (state, action: PayloadAction<RequestErrorState>) => {
            state.id = null;
            state.status = null;
            state.requestError = action.payload;
        },
        updateStatus: (state, action: PayloadAction<CalculationStatus | null>) => {
            state.status = action.payload;
        },
        stopCalculation: (state) => {
            state.id = null;
        },
        clearCalculation: (state) => {
            state.id = null;
            state.status = null;
            state.requestError = null;
        },
    },
});

export const {
    startCalculation,
    setCalculationId,
    setCalculationRequestError,
    updateStatus,
    stopCalculation,
    clearCalculation,
} = calculationSlice.actions;
export const calculationReducer = calculationSlice.reducer;
