import { useAzureAdAuthentication } from "@datamole/wds-azure-ad-auth";
import { Button } from "@datamole/wds-component-button";
import { Navbar as WdsNavbar } from "@datamole/wds-component-navbar";
import { Typography } from "@datamole/wds-component-typography";

import logoSrc from "@/assets/logo.png";
import { SignOutIcon } from "@/components/Icons";
import styles from "@/components/Navbar/Navbar.module.css";
import { clearAuth } from "@/store/slices/auth.slice";

export const Navbar = () => {
    const { triggerLogout, user } = useAzureAdAuthentication();

    const handleLogout = async () => {
        clearAuth();
        await triggerLogout();
    };

    return (
        <WdsNavbar className={styles.navbar}>
            <WdsNavbar.Logo className={styles.logo}>
                <img src={logoSrc} alt="Fonterra" />
                <Typography variant="lg" weight="medium">
                    New Product Development
                </Typography>
            </WdsNavbar.Logo>
            <WdsNavbar.Actions>
                <Typography>{user?.username}</Typography>
                <Button size="lg" variant="text" onClick={handleLogout}>
                    <SignOutIcon />
                </Button>
            </WdsNavbar.Actions>
        </WdsNavbar>
    );
};
