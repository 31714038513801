import type { PropsWithChildren } from "react";

import { Navbar } from "@/components/Navbar";

export const Layout = ({ children }: PropsWithChildren) => (
    <>
        <Navbar />
        <main>{children}</main>
    </>
);
