export enum TemperatureKey {
    LOW = "LOW",
    AMBIENT = "AMBIENT",
    HIGH = "HIGH",
    ULTRA_HIGH = "ULTRA_HIGH",
}

export type Temperature = {
    key: TemperatureKey;
    label: string;
    value: number;
    unit: string;
};
